<template>
  <ValidationObserver slim v-slot="{ invalid, untouched, handleSubmit }">
    <v-dialog
      content-class="product-modal"
      :value="showDialog"
      :persistent="false"
      width="700"
      @input="closeDialog"
    >
      <v-card>
        <v-card-title class="headline">
          <span
            ><i class="fa fa-shopping-cart"></i>
            {{
              currentItem ? "Редактирование товара" : "Добавление товара"
            }}</span
          >
        </v-card-title>

        <v-card-text>
          <form>
            <v-layout column>
              <v-row class="form-row">
                <v-col cols="12" class="form-column">
                  <ValidationProvider
                    name="agency_id"
                    rules="required"
                    v-slot="{ invalid, validated }"
                  >
                    <v-select
                      :disabled="agencyDisabled"
                      v-model="agency_id"
                      :items="agenciesListShort"
                      item-value="id"
                      item-text="name"
                      label="Агентство по товарам *"
                      hide-details="auto"
                      required
                      :error="invalid && validated"
                    ></v-select>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-row class="form-row">
                <v-col cols="12" class="form-column">
                  <ValidationProvider
                    name="category_id"
                    rules="required"
                    v-slot="{ invalid, validated }"
                  >
                    <v-select
                      @change="selectCategory"
                      v-model="category_id"
                      :items="categoriesListShort"
                      item-value="id"
                      item-text="name"
                      label="Категория *"
                      hide-details="auto"
                      required
                      :error="invalid && validated"
                    ></v-select>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-row class="form-row">
                <v-col cols="12" class="form-column">
                  <v-text-field
                    v-if="category_id"
                    :disabled="true"
                    label="Название раздела"
                    hide-details="auto"
                    v-model="section_name"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row class="form-row">
                <v-col cols="12" class="form-column">
                  <ValidationProvider
                    name="name"
                    rules="required"
                    v-slot="{ invalid, validated }"
                  >
                    <v-text-field
                      :error="invalid && validated"
                      label="Название *"
                      hide-details="auto"
                      v-model="name"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-row class="form-row">
                <v-col cols="12" class="form-column">
                  <ValidationProvider
                    name="price"
                    rules="min_value:1"
                    v-slot="{ invalid, validated }"
                  >
                    <v-text-field
                      label="Цена *"
                      type="text"
                      hide-details="auto"
                      v-model="price"
                      :error="invalid && validated"
                      min="1"
                      step="1"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-row class="form-row">
                <v-col cols="12" class="form-column">
                  <ValidationProvider
                    name="product_quantity"
                    rules="integer|min_value:1"
                    v-slot="{ invalid, validated }"
                  >
                    <v-text-field
                      label="Количество в пачке"
                      type="number"
                      hide-details="auto"
                      v-model="product_quantity"
                      :error="invalid && validated"
                      min="1"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-row class="form-row">
                <v-col cols="12" class="form-column">
                  <ValidationProvider
                    name="description"
                    rules="required"
                    v-slot="{ invalid, validated }"
                  >
                    <v-textarea
                      label="Описание *"
                      v-model="description"
                      :error="invalid && validated"
                    ></v-textarea>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-row class="form-row">
                <v-col cols="12" class="form-column">
                  <ValidationProvider
                    name="short_description"
                    rules="required"
                    v-slot="{ invalid, validated }"
                  >
                    <v-textarea
                      label="Краткое описание *"
                      v-model="short_description"
                      :error="invalid && validated"
                      rows="2"
                    ></v-textarea>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-container class="px-0 pb-0" fluid>
                <v-row>
                  <v-col
                    :class="{
                      'py-0': $vuetify.breakpoint.xs,
                    }"
                    sm="4"
                    cols="12"
                  >
                    <v-checkbox
                      v-model="is_popular"
                      label="Популярный?"
                    ></v-checkbox>
                  </v-col>
                  <v-col
                    :class="{
                      'py-0': $vuetify.breakpoint.xs,
                    }"
                    sm="4"
                    cols="12"
                  >
                    <v-checkbox
                      :class="{
                        'mt-0': $vuetify.breakpoint.xs,
                        'pt-0': $vuetify.breakpoint.xs,
                      }"
                      v-model="is_available"
                      label="Доступный?"
                    ></v-checkbox>
                  </v-col>
                  <v-col
                    :class="{
                      'pt-0': $vuetify.breakpoint.xs,
                    }"
                    sm="4"
                    cols="12"
                  >
                    <v-checkbox
                      :class="{
                        'mt-0': $vuetify.breakpoint.xs,
                        'pt-0': $vuetify.breakpoint.xs,
                      }"
                      v-model="is_hidden"
                      label="Скрытый?"
                    ></v-checkbox>
                  </v-col>
                  <v-col
                    v-if="productType !== SUBDIVISION_TYPE"
                    :class="{
                      'pt-0': $vuetify.breakpoint.xs,
                    }"
                    sm="4"
                    cols="12"
                  >
                    <v-checkbox
                      :class="{
                        'mt-0': $vuetify.breakpoint.xs,
                        'pt-0': $vuetify.breakpoint.xs,
                      }"
                      v-model="is_long_term_production"
                      label="Долгое производство?"
                    />
                  </v-col>
                  <v-col
                    v-if="productType !== SUBDIVISION_TYPE"
                    :class="{
                      'pt-0': $vuetify.breakpoint.xs,
                    }"
                    sm="4"
                    cols="12"
                  >
                    <v-checkbox
                      :class="{
                        'mt-0': $vuetify.breakpoint.xs,
                        'pt-0': $vuetify.breakpoint.xs,
                      }"
                      v-model="is_limited_series"
                      label="Количество товара ограничено?"
                    />
                  </v-col>
                </v-row>
                <v-row
                  v-if="
                    productType === SUBDIVISION_TYPE ||
                    DEALER_TYPE ||
                    !currentItem
                  "
                  class="form-row mt-3"
                >
                  <v-col cols="12" class="form-column">
                    <ValidationProvider name="articul">
                      <v-text-field
                        label="Артикул"
                        hide-details="auto"
                        v-model="articul"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="pt-0" cols="12">
                    <v-row class="mt-0" v-for="(size, key) in sizes" :key="key">
                      <v-col cols="10">
                        <v-text-field
                          v-model="size.name"
                          :key="key"
                          hide-details="auto"
                        />
                      </v-col>
                      <v-col class="d-flex align-end pl-0" cols="2">
                        <CButton
                          @click.prevent="removeSize(key)"
                          class=""
                          color="danger"
                        >
                          <i class="fa fa-close"></i>
                        </CButton>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12">
                    <CButton @click="addSize()" color="success">
                      <i class="mr-2 fa fa-plus"></i>
                      <span>Добавить размер</span>
                    </CButton>
                  </v-col>
                </v-row>
              </v-container>

              <v-layout class="attachments" column>
                <v-tabs v-model="tab">
                  <v-tab key="images" href="#images">Изображение</v-tab>
                  <v-tab key="files" href="#files">Файлы</v-tab>
                  <v-tabs-items v-model="tab">
                    <v-tab-item key="images" id="images" md-label="Изображение">
                      <v-container class="grey lighten-5 my-2">
                        <v-row class="product-images mb-3 mt-2 justify-start">
                          <v-col cols="auto" class="pr-0">
                            <input
                              :disabled="images.length >= 3"
                              type="file"
                              ref="pictupload"
                              @change="choosePicture"
                              accept="image/jpeg,image/png,application/pdf,image/x-eps"
                            />
                          </v-col>
                          <v-col sm="auto" cols="12" class="pl-0">
                            <CButton
                              size="sm"
                              :disabled="images.length >= 3 || !picture"
                              @click="uploadPicture"
                              color="success"
                              >Загрузить
                            </CButton>
                          </v-col>
                        </v-row>
                        <v-row class="product-images">
                          <v-col
                            v-for="(img, key) in images"
                            :key="key"
                            class="product-images__block"
                            :class="{
                              'col-12': $vuetify.breakpoint.width <= 440,
                              'col-6':
                                $vuetify.breakpoint.width > 440 &&
                                $vuetify.breakpoint.width <= 600,
                              'col-4': $vuetify.breakpoint.width > 600,
                            }"
                          >
                            <div
                              class="product-images__img"
                              :style="{
                                backgroundImage:
                                  typeof img != 'object'
                                    ? `url(${base_url}/api/images/` + img + ')'
                                    : `url(${img.src})`,
                              }"
                              alt=""
                            ></div>

                            <div class="product-images__arrows">
                              <div
                                :disabled="images.length == 1"
                                @click="movePicture(key, 'left')"
                                class="arrow"
                              >
                                <i class="fa fa-arrow-left"></i>
                              </div>
                              <div
                                :disabled="images.length == 1"
                                @click="movePicture(key, 'right')"
                                class="arrow"
                              >
                                <i class="fa fa-arrow-right"></i>
                              </div>
                            </div>

                            <button
                              type="button"
                              @click="removePicture(key)"
                              class="dropdown-item btn btn-danger product-images__remove-img-btn"
                            >
                              <i class="la la-trash"></i>
                              Удалить
                            </button>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-tab-item>
                    <v-tab-item key="files" id="files" md-label="Файлы">
                      <v-container fluid class="grey lighten-5 mb-2">
                        <v-layout column class="product-files mb-3">
                          <v-row
                            class="flex-wrap"
                            v-for="(file, key) in files"
                            :key="key"
                          >
                            <v-col
                              cols="auto"
                              v-if="files.length > 1"
                              class="product-files__arrows pl-0"
                            >
                              <div
                                @click="moveFile(key, 'left')"
                                class="arrow"
                                title="Переместить наверх"
                              >
                                <i class="fa fa-arrow-up"></i>
                              </div>
                              <div
                                @click="moveFile(key, 'right')"
                                class="arrow"
                                title="Переместить вниз"
                              >
                                <i class="fa fa-arrow-down"></i>
                              </div>
                            </v-col>
                            <v-col
                              cols="11"
                              class="product-files__name d-flex align-center pl-0"
                            >
                              <contenteditable
                                class="filename"
                                @blur.native="fileNameChange($event, file)"
                                :value="file.file_name"
                                tag="div"
                                :contenteditable="true"
                                :noNL="true"
                                :noHTML="true"
                              />
                            </v-col>

                            <v-col
                              sm="auto"
                              cols="12"
                              class="product-files__buttons d-flex pl-0"
                              :class="{
                                // 'ml-2': $vuetify.breakpoint.smAndUp,
                                'pt-0': $vuetify.breakpoint.xsOnly,
                              }"
                            >
                              <v-row
                                class="flex-wrap"
                                :class="{
                                  'flex-column':
                                    $vuetify.breakpoint.width < 325,
                                }"
                              >
                                <v-col cols="auto" class="d-flex align-center">
                                  <CButton
                                    @click="showFile(file)"
                                    color="info"
                                    size="sm"
                                    class="action-btn"
                                  >
                                    <i class="la la-eye mr-1"></i>
                                    Просмотреть
                                  </CButton>
                                </v-col>

                                <v-col
                                  cols="auto"
                                  class="d-flex align-center"
                                  :class="{
                                    'pt-0': $vuetify.breakpoint.width < 357,
                                  }"
                                >
                                  <CButton
                                    @click="removeFile(key)"
                                    color="danger"
                                    size="sm"
                                    class="action-btn"
                                    :class="{
                                      'ml-2': $vuetify.breakpoint.width > 364,
                                      // 'mt-2': $vuetify.breakpoint.width > 357,
                                    }"
                                  >
                                    <i class="la la-trash"></i>
                                    Удалить
                                  </CButton>
                                </v-col>
                              </v-row>
                              <!--                              </v-row>-->
                            </v-col>
                          </v-row>
                          <div class="product-files__upload">
                            <v-row>
                              <input
                                :disabled="files.length >= 2"
                                class="file"
                                @change="chooseFile"
                                type="file"
                                ref="fileupload"
                              />
                            </v-row>
                            <v-row>
                              <v-col sm="9" cols="12" class="pl-0">
                                <v-text-field
                                  label="Название *"
                                  type="text"
                                  hide-details="auto"
                                  class="name"
                                  v-model="file.file_name"
                                  :disabled="files.length >= 2"
                                ></v-text-field>
                              </v-col>
                              <v-col sm="3" cols="3" class="pl-0">
                                <CButton
                                  :disabled="
                                    files.length >= 2 || !file.file_name
                                  "
                                  @click="uploadFile"
                                  class="upload-btn"
                                  size="sm"
                                  color="success"
                                >
                                  Загрузить
                                </CButton>
                              </v-col>
                            </v-row>
                          </div>
                        </v-layout>
                      </v-container>
                    </v-tab-item>
                  </v-tabs-items>
                </v-tabs>
              </v-layout>
            </v-layout>
          </form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <CButton class="mr-2" color="secondary" @click="$emit('close')"
            >Отмена</CButton
          >
          <CButton @click="handleSubmit(submit)" color="success">
            <i class="fa fa-save mr-2"></i>
            <span>Сохранить</span>
          </CButton>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </ValidationObserver>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { serialize } from "object-to-formdata";
import EntityModalMixin from "@/views/grid_elements/mixins/EntityModalMixin";
import {
  Subdivision as SUBDIVISION_TYPE,
  Dealer as DEALER_TYPE,
} from "@/config/product_types";
import api from "@/api";
import { base_url } from "@/lib/axios";
const cloneDeep = require("lodash.clonedeep");

export default {
  name: "product-create-update-modal",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [EntityModalMixin],
  async mounted() {
    await this.fetchAgencyListShort();

    if (
      this.isCurrentUserAgentUser &&
      this.agenciesListShort &&
      this.agenciesListShort.length > 0
    ) {
      this.agency_id = this.agenciesListShort[0].id;
      this.agencyDisabled = true;
    }
    this.fetchCategoryListShort(this.productType);
  },
  props: {
    productType: {
      type: String,
      required: true,
    },
  },
  data() {
    let state = {
      SUBDIVISION_TYPE,
      DEALER_TYPE,
      base_url,
      tab: "",
      agency_id: null,
      agencyDisabled: false,
      category_id: null,
      section_name: "",
      name: "",
      price: 1,
      product_quantity: 1,
      description: "",
      short_description: "",
      articul: "",
      is_popular: false,
      is_available: false,
      is_hidden: false,
      is_limited_series: false,
      is_long_term_production: false,
      picture: null,
      images: [],
      file: {
        file_name: "",
        file: null,
      },
      files: [],
      sizes: [],
    };

    if (this.currentItem) {
      state = {
        ...state,
        ...cloneDeep(this.currentItem),
      };
    }

    return state;
  },
  methods: {
    createEntity: api.catalog.createProduct,
    updateEntity: api.catalog.updateProduct,
    ...mapActions("catalog", ["fetchCategoryListShort"]),
    ...mapActions("agency", ["fetchAgencyListShort"]),
    selectCategory(category_id) {
      const category = this.categoriesListShort.find(
        (category) => category.id === category_id
      );

      if (category) {
        this.section_name = category.section_name;
      }
    },
    addSize() {
      this.sizes.push({ name: "" });
    },
    removeSize(index) {
      this.sizes.splice(index, 1);
    },
    choosePicture(e) {
      let files = e.target.files || e.dataTransfer.files;

      if (files.length) {
        this.picture = files[0];
      }
    },
    uploadPicture() {
      if (this.picture) {
        const file = this.picture;
        this.$refs.pictupload.value = null;

        this.picture = null;
        this.images = [
          ...this.images,
          {
            src: URL.createObjectURL(file),
            file,
          },
        ];
      }
    },
    movePicture(currentIndex, direction) {
      const images = this.images;

      if (images.length > 1) {
        let siblingIndex = currentIndex + (direction === "right" ? 1 : -1);

        siblingIndex;
        siblingIndex + 1 === images.length;

        if (siblingIndex < 0) {
          siblingIndex = images.length - 1;
        } else if (siblingIndex === images.length) {
          siblingIndex = 0;
        }

        const tmp = images[currentIndex];
        images[currentIndex] = images[siblingIndex];
        this.$set(images, siblingIndex, tmp);
      }
    },
    removePicture(index) {
      const images = this.images;
      images.splice(index, 1);

      this.images = [...images];
    },
    chooseFile(e) {
      let files = e.target.files || e.dataTransfer.files;

      if (files.length) {
        const file = files[0];
        this.file = {
          file: files[0],
          file_name: file.name,
        };
      }
    },
    uploadFile() {
      if (this.file && this.file.file) {
        const file = this.file;
        this.$refs.fileupload.value = null;

        file.file = new File([file.file], file.file_name);

        this.file = {
          name: "",
          file: null,
        };

        this.files = [...this.files, file];
      }
    },
    moveFile(currentIndex, direction) {
      const files = this.files;

      if (files.length > 1) {
        let siblingIndex = currentIndex + (direction === "right" ? 1 : -1);

        if (siblingIndex < 0) {
          siblingIndex = files.length - 1;
        } else if (siblingIndex === files.length) {
          siblingIndex = 0;
        }

        const tmp = files[currentIndex];
        files[currentIndex] = files[siblingIndex];
        this.$set(files, siblingIndex, tmp);
      }
    },
    removeFile(index) {
      const files = this.files;
      files.splice(index, 1);

      this.files = [...files];
    },
    showFile(file) {
      let url;

      if (file.id) {
        url = `${base_url}/api/files/${file.id}`;
      } else {
        url = URL.createObjectURL(file.file);
      }

      window.open(url, "_blank");
    },
    prepareCreateEntityParams() {
      return [this.productType, this.data];
    },
    fileNameChange(e, file) {
      const fileName = e.target.textContent;

      if (fileName) {
        this.$set(file, "file_name", fileName);

        if (file.file) {
          file.file = new File([file.file], fileName);

          file.file;
        }
      } else {
        e.target.textContent = file.file_name;
      }

      file.file.name;
    },
  },
  computed: {
    ...mapState("agency", ["agenciesListShort"]),
    ...mapState("catalog", ["categoriesListShort"]),
    data() {
      const data = serialize({
        name: this.name,
        is_popular: this.is_popular ? 1 : 0,
        is_available: this.is_available ? 1 : 0,
        is_hidden: this.is_hidden ? 1 : 0,
        is_limited_series: this.is_limited_series ? 1 : 0,
        is_long_term_production: this.is_long_term_production ? 1 : 0,
        product_quantity: this.product_quantity,
        short_description: this.short_description,
        articul: this.articul,
        description: this.description,
        category_id: this.category_id,
        agency_id: this.agency_id,
        stock_balance: this.stock_balance,
        price: this.price,
      });

      const images = this.images.map((item) =>
        typeof item === "object" ? item.file : item
      );
      const files = this.files.map((item) => (item.file ? item.file : item));
      const sizes = this.sizes;

      images.forEach((item, key) => {
        data.append(`images[${key}]`, item);
      });

      files.forEach((item, key) => {
        data.append(
          `files[${key}]`,
          item instanceof File ? item : JSON.stringify(item)
        );
      });

      sizes.forEach((item, key) => {
        data.append(`sizes[${key}]`, JSON.stringify(item));
      });

      return data;
    },
  },
};
</script>
